import React from 'react';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionCageListDimensions = props => {
  const {
    publicData: { cageDimension },
    heading,
  } = props;
  const dimension =
    !!cageDimension &&
    `${cageDimension.width}'W × ${cageDimension.length}'L × ${cageDimension.height}'H`;

  return (
    !!cageDimension && (
      <div className={css.sectionText}>
        {!!heading && (
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        )}
        <p className={css.text}>{dimension}</p>
      </div>
    )
  );
};

export default SectionCageListDimensions;
